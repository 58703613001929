import { Box, Button,IconButton,Typography } from "@mui/material";
import React from "react";
import logoshop from '../assets/shopcotu.png'
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CopyrightIcon from '@mui/icons-material/Copyright';

import { useMediaQuery } from 'react-responsive'

const Footer = () => {
  const [open, setOpen] = React.useState(true);
  const [open1, setOpen1] = React.useState(true);
  const [open2, setOpen2] = React.useState(true);
  const [open3, setOpen3] = React.useState(true);
  const [open4, setOpen4] = React.useState(true);
  const [open5, setOpen5] = React.useState(true);
  const [open6, setOpen6] = React.useState(true);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })


  return (
    <div className="footer">     
    {/* {isTabletOrMobile && (<div>
      <p>You are a tablet or mobile phone </p>
      {console.log({open})}
      {open ? setOpen(false) : null} 
      </div>)} */}
    <Box display="flex" marginTop='3rem' flexDirection={{xs: 'column',sm:'row'}} >
      <Box flex="1 1 50%"  >
        <Box display="flex" flexDirection="column" justifyContent='space-between' alignItems='start' minHeight={{xs:'10vh',sm:'90vh'}} borderBottom={{xs:"2px solid #24632b", sm:'none'}}>
          <div style={{margin:'1rem'}}>
            <h5 style={{margin:'1rem 0 '}}>SUBSCRIBE TO THE SHOPCOTU NEWSLETTER</h5>
            <p>
              Sign up and receive 20 NZD off for your next order above 400 NZD
            </p>
            <Button
              variant="contained"              
              sx={{
                bgcolor: "#f3fbf7",
                color: "#24632b",
                borderRadius: "5px",
                fontWeight: 600,
                border: "1px solid #24632b",
                "&:hover": {
                  bgcolor: "#24632b",
                  color: "#fff",
                },
                maxWidth:100,
                margin:'1rem 0'
              }}
              href="/register"
              className="animated tada"
            >
              SIGN UP
            </Button>
          </div>
          <div style={{margin:'2rem'}}>
          <img src={logoshop} width={60}/>
          </div>
        </Box>
      </Box>
      <Box flex="1 1 20%" borderLeft={{xs:'none', sm: '1px solid #24632b'}} >   
      <List
      sx={{ width: '100%', bgcolor: 'transparent', display:'flex', flexDirection:'column', maxWidth:'360px' }}
    >
      {/* section 1 */}
      <ListItemButton onClick={()=>setOpen(!open)}>
        <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>NEED SOME HELP?</Typography>} sx={{fontFamily:"Josefin Sans, sans-serif"}}/>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 2 }} dense>
              <ListItemText primary={<Typography  sx={{fontFamily:"Josefin Sans, sans-serif"}}>Contact Us</Typography>}/>
            </ListItemButton>
            <ListItemButton sx={{ pl: 2 }} dense>
              <ListItemText primary={<Typography  sx={{fontFamily:"Josefin Sans, sans-serif"}}>FAQ's</Typography>}/>
            </ListItemButton>
            <ListItemButton sx={{ pl: 2, mb:4 }} dense>
              <ListItemText primary={<Typography  sx={{fontFamily:"Josefin Sans, sans-serif"}}>Customer Service</Typography>}/>
            </ListItemButton>
          </List>
        </Collapse>
        {/* setion 2 */}
        <ListItemButton onClick={()=>setOpen1(!open1)}>
          <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif",}}>ORDERS</Typography>} sx={{fontFamily:"Josefin Sans, sans-serif"}}/>
          {open1 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 2 }} >
              <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Shipping & Delivery</Typography>}/>
            </ListItemButton>
            <ListItemButton sx={{ pl: 2 }} dense>
              <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Returns & Refunds</Typography>}/>
            </ListItemButton>
            <ListItemButton sx={{ pl: 2 }} dense>
              <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Follow your Order</Typography>}/>
            </ListItemButton>
            <ListItemButton sx={{ pl: 2 }} dense>
              <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Return Form</Typography>}/>
            </ListItemButton>
            <ListItemButton sx={{ pl: 2, mb:4 }} dense>
              <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Follow your Return</Typography>}/>
            </ListItemButton>
          </List>
        </Collapse>
                {/* setion 3 */}
                <ListItemButton onClick={()=>setOpen2(!open2)}>
          <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif",}}>SERVICES</Typography>} sx={{fontFamily:"Josefin Sans, sans-serif"}}/>
          {open1 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open2} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 2 }} >
              <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Engraving & Embossing</Typography>}/>
            </ListItemButton>
            <ListItemButton sx={{ pl: 2 }} dense>
              <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Gift Wrapping</Typography>}/>
            </ListItemButton>
            <ListItemButton sx={{ pl: 2 }} dense>
              <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Corporate Gifts</Typography>}/>
            </ListItemButton>
            <ListItemButton sx={{ pl: 2 }} dense>
              <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Refill Finder</Typography>}/>
            </ListItemButton>
            <ListItemButton sx={{ pl: 2, }} dense>
              <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Product Care</Typography>}/>
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      </Box>
      <Box flex="1 1 30%" borderLeft={{xs:'none', sm: '1px solid #24632b'}}  minHeight={{xs:'20vh',sm:'90vh'}} display="flex" flexDirection='column' justifyContent="space-between">        
          <List
          
          sx={{ width: '100%', bgcolor: 'transparent', display:'flex', flexDirection:'column', maxWidth:'360px'
         }}
        >
          <ListItemButton onClick={()=>setOpen3(!open3)}>
            <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif", }}>ABOUT US</Typography>} sx={{fontFamily:"Josefin Sans, sans-serif"}}/>
            {open3 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl:2 }}>
                <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>About SHOPCOTU</Typography>}/>
              </ListItemButton>
              <ListItemButton sx={{ pl: 2 }}>
                <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Sustainability</Typography>}/>
              </ListItemButton>
              <ListItemButton sx={{ pl: 2, mb:4}}>
                <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Careers </Typography>}/>
              </ListItemButton>
            </List>
          </Collapse>
                  {/* SECTION 5 */}
                  <ListItemButton onClick={()=>setOpen4(!open4)}>
            <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif",}}>TERMS & LEGAL</Typography>} sx={{fontFamily:"Josefin Sans, sans-serif"}}/>
            {open4 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open4} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 2 }}>
                <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Privacy & Security</Typography>}/>
              </ListItemButton>
              <ListItemButton sx={{ pl: 2, mb:4}}>
                <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif"}}>Cookie Policy</Typography>}/>
              </ListItemButton>
            </List>
          </Collapse>
        {/* SECTON 6 */}

          <ListItemButton onClick={()=>setOpen5(!open5)}>
            <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif",}}>PAYMENT METHODS</Typography>} sx={{fontFamily:"Josefin Sans, sans-serif"}}/>
            {open5 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open5} timeout="auto" unmountOnExit>
              <Box display='flex' sx={{pl:2,mb:4}}>
                  <div className="card-name">
                  <img
                    alt="mastercard"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/1200px-MasterCard_Logo.svg.png"
                  />
                </div>
                <div className="card-name">
                  <img
                    alt="visa"
                    src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
                  />
                </div>
                <div className="card-name">
                  <img
                    alt="paypal"
                    src="https://wiki.soulcams.com/images/9/9a/Paypal-logo.png"
                  />
                </div>
                {/* <div className="card-name">
                  <img
                    alt="express"
                    src="https://icons.iconarchive.com/icons/designbolts/credit-card-payment/256/American-Express-icon.png"
                  />
                </div> */}
                <div className="card-name">
                  <img
                    alt="google pay"
                    src="https://pay.google.com/about/static_kcs/images/logos/google-pay-logo.svg"
                  />
                </div>
              </Box>
          </Collapse>

          {/* SECTION 7 */}
          <ListItemButton onClick={()=>setOpen5(!open5)}>
            <ListItemText primary={<Typography sx={{fontFamily:"Josefin Sans, sans-serif", }}>FOLLOW US</Typography>} sx={{fontFamily:"Josefin Sans, sans-serif"}}/>
            {open5 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open5} timeout="auto" unmountOnExit>
          <Box display='flex' sx={{pl:2}}>
              <IconButton >
                <InstagramIcon />
                
              </IconButton>
              <IconButton >
                <FacebookOutlinedIcon />
                
              </IconButton>
              <IconButton >
                <YouTubeIcon />                
              </IconButton>
              </Box>
          </Collapse>
        </List>
        <Typography margin='2rem 1rem'> <i class="fa fa-copyright"></i> 2024 Shopcotu</Typography>
      </Box>
    </Box>
    </div>
  );
};

export default Footer;
