import React from "react";
import "./search.css";
import RelatedProducts from "../RelatedProduct/RelatedProducts";
import { Typography } from "@mui/material";
import SearchSuggested from "./searchsuggested";

const Search = () => {
  return (
    <div class="box1">
      <div className="d-flex align-items-center" style={{ height: "60px" }}>
        <Typography
          display={{ xs: "none", sm: "block" }}
          sx={{
            fontFamily: "Josefin Sans, sans-serif",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          Search
        </Typography>
        <form name="search">
          <input
            type="text"
            class="input"
            name="txt"
            onmouseout="this.value = 'Enter search here...'; this.blur();"
          />
          <i class="fas fa-search" style={{ display: "inline-block" }}></i>
        </form>
      </div>
      <div class="slide1">
        <SearchSuggested />
    </div>
    </div>
  );
};

export default Search;
