import React, { Fragment } from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { Link, useHistory } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import WithScrollbar from "./WithScrollbar";
import Section from "./Section";
// import "./categiries.css";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MaximizeIcon from '@mui/icons-material/Maximize';
import './suggested.css'

const SearchSuggested = () => {

    const setfilteredProduct = useSelector(
        (state) => state.filterProductListReducer.setfilteredProduct
      );
      // console.log(setfilteredProduct)
      const allCategories = [
        ...new Set(setfilteredProduct.map((item) => item.category)),
        "all",
      ];
      console.log({allCategories});
    
      const allBrands= [
        ...new Set(setfilteredProduct.map((item) => item.company)),
        "all",
      ]

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 770 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 2,
    },
  };

  const sliderImageUrl = [
    //First image url
    {
      url: "https://www.sauceshop.co/cdn/shop/files/SauceShopWebsiteImagesSqueezy_CurryKetchup_900x.png?v=1692257509",
      name: "SAUCE SHOP X ONLY SCRANS - CURRY KETCHUP",
      price: 3.99,
    },
    {
      url: "https://www.sauceshop.co/cdn/shop/files/SAUCESHOPJARWEBSITEIMAGES_RanchMayo_900x.png?v=1687508711",
      name: "RANCH MAYO",
      price: 3.49,
    },
    //Second image url
    {
      url: "https://www.sauceshop.co/cdn/shop/products/SAUCESHOPGLASSSAUCEWEBSITEIMAGES_BrusselSproutKetchup_fa8c21a2-c87b-408a-8e6c-e44dca181d1e_900x.png?v=1676556484",
      name: "BRUSSELS SPROUT KETCHUP",
      price: 3.49,
    },
    //Third image url
    {
      url: "https://www.sauceshop.co/cdn/shop/files/SAUCESHOPJARWEBSITEIMAGES_ScotchBonnetJam-14_ee574d7b-0011-48fe-9d54-41208a91f6e8_900x.png?v=1695821357",
      name: "SAUCE SHOP X 12:51 - SCOTCH BONNET CHILLI JAM",
      price: 3.99,
    },

    //Fourth image url

    {
      url: "https://www.sauceshop.co/cdn/shop/products/SAUCESHOPGLASSSAUCEWEBSITEIMAGES_SmokyChipotleKetchup_e2db5229-e45f-48b9-8e4f-0fab72dfbe89_900x.png?v=1691068075",
      name: "SMOKY CHIPOTLE KETCHUP",
      price: 3.49,
    },
  ];

  const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
      <li
        className={active ? "active" : "inactive"}
        onClick={() => onClick()}
      >
        <MaximizeIcon />
      </li>
    );
  };

  const arrowStyle = {
    background: "transparent",
    border: 0,
    color: "#red",
    fontSize: "80px"
  };
  const CustomRight = ({ onClick }) => (
    <button className="arrow right" onClick={onClick} style={arrowStyle}>
      <ArrowForwardIosIcon style={{ fontSize: "10px" }} />
    </button>
  );
  const CustomLeft = ({ onClick }) => (
    <button className="arrow left" onClick={onClick} style={arrowStyle}>
      <ArrowBackIosIcon style={{ fontSize: "10px" }} />
    </button>
  );
  return (
    <div className="container">
        <h5>Trending Searches</h5>
        <Carousel
            responsive={responsive}
            additionalTransfrom={0}
            arrows
            // autoPlaySpeed={3000}
            centerMode={false}
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            // showDots
            sliderClass=""
            slidesToSlide={1}
            swipeable
            customDot={<CustomDot />}
            customRightArrow={<CustomRight />}
            customLeftArrow={<CustomLeft />}
            autoPlay={true}
            partialVisible={false}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
          >
            
            {allCategories.map((cat,i)=>(
                             <Link to="/shop">
                             <div className="slider" key={i}>
                               
                               <p>{cat}</p>
                             </div>
                           </Link>
            ))}
            </Carousel>
            <h4>Product Suggestions</h4>


          <Carousel
            responsive={responsive}
            autoPlay={true}
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={true}
            partialVisible={false}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            arrows={false}
          >
            {sliderImageUrl.map((imageUrl, index) => {
              return (
                <Link to="/shop">
                  <div className="slider" key={index}>
                    <img src={imageUrl.url} alt="movie" />
                    <p>{imageUrl.name}</p>
                    <small>{imageUrl.price}</small>
                  </div>
                </Link>
              );
            })}
          </Carousel>
          {/* <WithScrollbar /> */}

    </div>
  );
};

export default SearchSuggested;
