import React from "react";
import Carousel from "react-multi-carousel";
import { Link, useHistory } from "react-router-dom";
import "./herostyles.css";
import { Typography, Box, TablePagination,Button } from "@mui/material";
import hero1 from "../Asset/hero1.webp";

const Hero = () => {
  
  return (
    <>
      <div className="">
        <div class="logos">
          <div class="logos-slide">
            <p>Natural ingredients</p>
            <p>Made in Vietnam</p>
            <p>Over 30 flavours</p>
          </div>
        </div>
        <Box
          display="flex"
          flex="1 1"
          flexDirection={{ xs: "column-reverse", sm: "row" }}
          maxWidth="1280px"
        >
          <Box
            display='flex'
            flex="1 1 40%"
            alignItems="center"
            justifyContent="center"            
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              marginLeft={5}
              marginRight={8}
              margin="2rem"
            >
              <h1 style={{fontWeight: 700}}>
                EFFORTLESS FLAVOUR
              </h1>
              <h6 style={{fontWeight: 600}}>
                Game-changing sauces for 2024
              </h6>
              <p style={{marginBottom:'1rem'}}>
                Fill up over 30 flavours of award-winning ketchup, BBQ sauce,
                hot sauce, mayo and more
              </p>
              <Button
        variant="contained"
        sx={{
          bgcolor: "#f3fbf7",
          color:'#24632b',
          borderRadius:'5px',
          fontWeight:600,
          border:'1px solid #24632b',
          "&:hover": {
            bgcolor: "#24632b",
            color:'#fff'
          },
        }}
        href="/shop"
        className="animated tada"
      >
        Brower Offers
      </Button>
            </Box>
          </Box>
          <Box flex="1 1 60%">
            <img src={hero1} alt="hero1" width="100%" />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Hero;
