
import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DropDown1 from './SlideDropdown/DropDown1';
import DropDown2 from './SlideDropdown/DropDown2';
import { styled } from '@mui/material/styles';




const MenuMobileSlide = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #24632b',
    '& .MuiTabs-indicator': {
      backgroundColor: '#24632b',
    },
  });
  
  const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'uppercase',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(0),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#24632b',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }));
  return (
    <>
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
        <AntTabs value={value} onChange={handleChange} aria-label="basic tabs example"  variant="fullWidth">
          <AntTab label="Trending" {...a11yProps(0)} sx={{ borderRight: 1, borderColor: 'divider',fontFamily:"Josefin Sans, sans-serif" }}/>
          <AntTab label="Discover" {...a11yProps(1)} sx={{ borderRight: 1, borderColor: 'divider',fontFamily:"Josefin Sans, sans-serif" }}/>
        </AntTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <DropDown1 />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <DropDown2 />
      </CustomTabPanel>
    </Box>
    </>
  )
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, borderBottom:'red' }}>
          <Typography color="#24632b">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default MenuMobileSlide