import './menumulti.css'
import { useDispatch, useSelector } from "react-redux";
import { sendBrand, sendCategory } from "../../Redux/Actions/ProductActions";
import React, { useState, useEffect, useRef } from 'react';
import DropDown1 from './SlideDropdown/DropDown1';
import DropDown2 from './SlideDropdown/DropDown2';


const Menumulty = () => {

  return (
    
    <div class="cm-e-menu">
    <ul>
        <li class="topmenu">
            <a>Trending</a>
            <ul class="submenu">
                <DropDown1 />
                {/* <li>Hot deals</li>
                <li>Trending in New Zealand</li>
                <li className='divider'></li>
                <li>Contact us</li>
                <li>Customer service</li> */}
    

            </ul>
        </li>
        <li class="topmenu">
            <a>Discover</a>
            <ul class="submenu">
            <DropDown2 />
            </ul>
            {/* <ul class="submenu">
                <li>Categories</li>
                <li>Brand</li>
            <li className='divider'></li>
                <li>Contact us</li>
                <li>Customer service</li>
            </ul> */}
        </li>
        {/* <li class="topmenu">
            <a>view</a>
            <ul class="submenu">
                <li><a>hide tabs</a></li>
                <li><a>hide menu</a></li>
                <li class="divider"></li>
                <li><a>wordwrap</a></li>
                <li><a>line numbers</a></li>
                <li><a>fullscreen</a></li>
                <li class="divider"></li>
                <li><a>highlight active line</a></li>
                <li>
                    <a>sidebar</a>
                    <ul class="submenu">
                        <li><a>hide sidebar</a></li>
                    </ul>
                </li>
            </ul>
        </li>
        <li class="topmenu"><a>find</a></li>
        <li class="topmenu">
            <a>help</a>
            <ul class="submenu">
                <li><a>support</a></li>
                <li><a>documentation</a></li>
                <li class="divider"></li>
                <li><a>bug report</a></li>
            </ul>
        </li>
        <li class="topmenu">
            <a>settings</a>
            <ul class="submenu">
                <li>
                    <a>font</a>
                    <ul class="submenu">
                        <li><a>larger</a></li>
                        <li><a>smaller</a></li>
                        <li class="divider"></li>
                        <li><a>default</a></li>
                    </ul>
                </li>
                <li>
                    <a>indentation</a>
                    <ul class="submenu">
                        <li><a>+1 space</a></li>
                        <li><a>-1 space</a></li>
                        <li class="divider"></li>
                        <li><a>default</a></li>
                    </ul>
                </li>
            </ul>
        </li> */}
    </ul>
</div>
  )
}



export default Menumulty