// import Button from "../components/Button";
import "./Recommended.css";
import React, { useEffect, useState } from "react";
import { Box,  IconButton, Typography, Button } from "@mui/material";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Drawer from '@mui/material/Drawer';
import WrapTextOutlinedIcon from '@mui/icons-material/WrapTextOutlined';


const Recommended = ({ handleClick }) => {

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
  
    setState({ ...state, [anchor]: open });
  };
  
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <h1>show drawer</h1>
    </Box>
  );



  const [sort, setSort] = useState("");
  return (
    <>
      <div>
        {/* <h2 className="recommended-title">Recommended</h2> */}
                {/* Sort Products */}
        <Box display="flex" alignItems="end">
          <label >Sort By :</label>
          {/* PC version */}
          <Box display={{xs:"none", sm:'block'}}>         
          <select value={sort} onChange={(e) => setSort(e.target.value)} style={{border:'none',  marginRight:'1rem'}}>
            <option value="" style={{marginLeft: "-500px"}}></option>
            <option value="latest" style={{width: "500px"}}>Latest</option>
            <option value="lowest-price">Lowest Price</option>
            <option value="highest-price">Highest Price</option>
            <option value="a-z">A - Z</option>
            <option value="z-a">Z - A</option>
          </select>
          </Box>
          {/* Mobile version */}
          <Box display={{xs:'flex',sm:'none'}} alignItems='center'>
              {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                  
                  <div onClick={toggleDrawer(anchor, true) } className='px-3 rounded text-capitalise'><WrapTextOutlinedIcon /></div>
                  <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
                </React.Fragment>
              ))}
          </Box>
          <Box>

          </Box>
        </Box>
        {/* <div className="recommended-flex">
          {brands.map((brand)=>(
            <Button onClickHandler={handleClick} value={brand.value} title={brand.title} />
          ))} */}
          {/* <Button onClickHandler={handleClick} value="" title="All Products" />
          <Button onClickHandler={handleClick} value="Bong Mai" title="Bông Mai" />
          <Button onClickHandler={handleClick} value="Cholimex" title="Cholimex" />
          <Button onClickHandler={handleClick} value="Puma" title="Puma" />
          <Button onClickHandler={handleClick} value="Vans" title="Vans" /> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default Recommended;
