import React, { useEffect, useState } from "react";
import Header from "./../components/Header";
import Rating from "../components/homeComponents/Rating";
import { Link } from "react-router-dom";
import Message from "./../components/LoadingError/Error";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductReview,
  listProductDetails,
} from "../Redux/Actions/ProductActions";
import Loading from "../components/LoadingError/Loading";
import { PRODUCT_CREATE_REVIEW_RESET } from "../Redux/Constants/ProductConstants";
import moment from "moment";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import RelatedProducts from "../components/RelatedProduct/RelatedProducts";
import Benefits from "../components/RelatedProduct/Benefits";

//MATERIAL UI SECTION
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardActions from "@mui/material/CardActions";

const SingleProduct = ({ history, match }) => {
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const productId = match.params.id;
  const dispatch = useDispatch();

  const [selectedSize, setSelectedSize] = useState();
  const [showError, setShowError] = useState(false);

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    loading: loadingCreateReview,
    error: errorCreateReview,
    success: successCreateReview,
  } = productReviewCreate;

  useEffect(() => {
    if (successCreateReview) {
      alert("Review Submitted");
      setRating(0);
      setComment("");
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
    dispatch(listProductDetails(productId));
  }, [dispatch, productId, successCreateReview]);

  const AddToCartHandle = (e) => {
    e.preventDefault();
    history.push(`/cart/${productId}?qty=${qty}`);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProductReview(productId, {
        rating,
        comment,
      })
    );
  };

  const dates = [
    {
      size: "Mon",
      enabled: false,
    },
    {
      size: "Tue",
      enabled: false,
    },
    {
      size: "Wed",
      enabled: false,
    },
    {
      size: "Thu",
      enabled: true,
    },
    {
      size: "Fri",
      enabled: true,
    },
    {
      size: "Sat",
      enabled: true,
    },
    {
      size: "Sun",
      enabled: true,
    },
    
  ];
  // Material section
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  const [expanded, setExpanded] = React.useState(false);
  const [expanded1, setExpanded1] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleExpandClick1 = () => {
    setExpanded1(!expanded1);
  };
  const handleExpandClick2 = () => {
    setExpanded2(!expanded2);
  };
  return (
    <>
      <Header />
      <div className="container single-product">
        {loading ? (
          <Loading />
        ) : error ? (
          <Message variant="alert-danger">{error}</Message>
        ) : (
          <>
            <div className="row">
              <div className="col-md-6">
                {/* <div className="single-image">
                  <img src={product.image} alt={product.name} />
                </div> */}
                <div className="d-flex flex-column">
                  <Carousel
                    infiniteLoop={true}
                    showIndicators={false}
                    showStatus={false}
                    thumbWidth={60}
                    className="productCarousel"
                  >
                    {product.images?.map((img) => (
                    <img
                        key={img}
                        src={img}
                        alt={img}
                    />
                ))}
                    {/* <img src="/images/p2.png" />
                    <img src="/images/p3.png" />
                    <img src="/images/p4.png" />
                    <img src="/images/p5.png" />
                    <img src="/images/p6.png" />
                    <img src="/images/p7.png" /> */}
                  </Carousel>
                </div>
              </div>
              <div className="col-md-6">
                <div className="product-dtl">
                  <div className="product-info">
                    <div className="product-name">{product.name}</div>
                    <div className="flex-box d-flex justify-content-start align-items-center">
                      <Rating
                        value={product.rating}
                        text={`${product.numReviews} reviews`}
                      />
                    </div>
                  </div>
                  

                  <div className="product-count col-lg-12 ">
                    <div className="flex-box d-flex justify-content-between align-items-center">
                      <h6>Price</h6>
                      <span>${product.price}</span>
                    </div>
                    <div className="flex-box d-flex justify-content-between align-items-center">
                      <h6>Status</h6>
                      {product.countInStock > 0 ? (
                        <span>In Stock</span>
                      ) : (
                        <span>unavailable</span>
                      )}
                    </div>

                    {product.countInStock > 0 ? (
                      <>
                        <div className="flex-box d-flex justify-content-between align-items-center">
                          <h6>Quantity</h6>
                          <select
                            value={qty}
                            onChange={(e) => setQty(e.target.value)}
                          >
                            {[...Array(product.countInStock).keys()].map(
                              (x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {/* -------------- */}
                        {/* PRODUCT SIZE RANGE START */}
                        <div className="mb-10">
                          {/* HEADING START */}
                          <div className="d-flex justify-content-between my-2">
                            <div className="text-md font-semibold">
                              Select Time to Pick up
                            </div>
                           </div>
                          {/* HEADING END */}

                          {/* SIZE START */}
                          <div
                            style={{display:'grid', gap: '5px',gridTemplateColumns:'auto auto auto auto auto auto auto'}}
                          >
                            {dates.map((date, i) => (
                              <div
                                key={i}
                                className={`sizeselected ${
                                  date.enabled
                                    ? "enablesizeselected"
                                    : "cursor-not-allowed bg-black/[0.1] opacity-50"
                                } ${
                                  selectedSize === date.size
                                    ? "bg-black text-white"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSelectedSize(date.size);
                                  setShowError(false);
                                }}
                              >
                                {date.size}
                              </div>
                            ))}
                          </div>
                          {/* SIZE END */}

                          {/* SHOW ERROR START */}
                          {showError && (
                            <div className="text-red-600 mt-1">
                              Size selection is required
                            </div>
                          )}
                          {/* SHOW ERROR END */}
                        </div>
                        {/* PRODUCT SIZE RANGE END */}

                        {/* -------------- */}
                        <button
                          onClick={AddToCartHandle}
                          className="round-black-btn mt-5 mb-5"
                        >
                          Add To Cart
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
                {/* CARD COLLAPSE */}
                <Card sx={{ maxWidth: "100%", mt:'1rem',  borderTop:'1px solid gray'}} variant='none'>
                  <CardActions disableSpacing>
                    <Typography color="text.primary" sx={{fontWeight: 600, fontFamily:'Josefin Sans, sans-serif', textTransform:'uppercase'}}>
                      Product description
                    </Typography>
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography paragraph sx={{fontFamily:'Josefin Sans, sans-serif'}} >{product.description}</Typography>
                    </CardContent>
                  </Collapse>
                </Card>
                <Card sx={{ maxWidth: "100%", mt:'1rem', borderTop:'1px solid gray'}} variant='none'>
                  <CardActions disableSpacing>
                    <Typography color="text.primary" sx={{fontWeight: 600, fontFamily:'Josefin Sans, sans-serif', textTransform:'uppercase'}}>
                      60 day returns
                    </Typography>
                    <ExpandMore
                      expand={expanded1}
                      onClick={handleExpandClick1}
                      aria-expanded={expanded1}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expanded1} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography paragraph sx={{fontFamily:'Josefin Sans, sans-serif'}}>If you're not completely satisfied, you are welcome to exchange your purchase* with the original receipt for a full refund, store credit, or an alternative product, within 60 days.</Typography>
                      <Typography paragraph sx={{fontFamily:'Josefin Sans, sans-serif'}}>*Excludes next-to-skin swimwear, masks, underwear, food, gas canisters, helmets[1], goggles[2], and gift vouchers.</Typography>
                      <Typography paragraph sx={{fontFamily:'Josefin Sans, sans-serif'}}>Due to safety reasons, helmets cannot be accepted for return unless there is a clear manufacturing error or failure.</Typography>
                      <Typography paragraph sx={{fontFamily:'Josefin Sans, sans-serif'}}>Due to hygiene reasons, goggles cannot be accepted for return unless there is a clear manufacturing error or failure..</Typography>

                    </CardContent>
                  </Collapse>
                </Card>
                <Card sx={{ maxWidth: "100%", mt:'1rem',borderTop:'1px solid gray' }} variant='none' >
                  <CardActions disableSpacing>
                    <Typography color="text.primary" sx={{fontWeight: 600, textTransform:'uppercase', fontFamily:'Josefin Sans, sans-serif'}}>
                      warranty and repairs
                    </Typography>
                    <ExpandMore
                      expand={expanded2}
                      onClick={handleExpandClick2}
                      aria-expanded={expanded2}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expanded2} timeout="auto" unmountOnExit>
                    <CardContent>
                      <Typography paragraph sx={{fontFamily:'Josefin Sans, sans-serif'}}>We trust our gear completely. If a product fails due to a major manufacturing fault or material defect, you can ask for a replacement, refund, or we’ll repair it free of charge. For non-Macpac branded products, we’ll always work with you to determine whether a repair, replacement or refund is appropriate.</Typography>
                      <Typography paragraph sx={{fontFamily:'Josefin Sans, sans-serif'}}>Our commitment to quality</Typography>

                    </CardContent>
                  </Collapse>
                </Card>
                {/* CARD COLLAPSE */}
              </div>
            </div>

            {/* RATING */}
            <div className="row my-5">
              <div style={{margin: '1rem auto', textAlign:'center'}}>
              <h3 >REVIEWS</h3>
              <hr style={{maxWidth: 100, margin: '0 auto', border: '3px solid green', marginBottom: '2rem'}}/>
              </div>
              <div className="col-md-6">
                <h6 className="mb-3">LIST REVIEWS</h6>
                {product.reviews.length === 0 && (
                  <Message variant={"alert-info mt-3"}>No Reviews</Message>
                )}
                {product.reviews.map((review) => (
                  <div
                    key={review._id}
                    className="mb-5 mb-md-3 bg-light p-3 shadow-sm rounded"
                  >
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <span>{moment(review.createdAt).calendar()}</span>
                    <div className="alert alert-info mt-3">
                      {review.comment}
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-md-6">
                <h6>WRITE A CUSTOMER REVIEW</h6>
                <div className="my-4">
                  {loadingCreateReview && <Loading />}
                  {errorCreateReview && (
                    <Message variant="alert-danger">
                      {errorCreateReview}
                    </Message>
                  )}
                </div>
                {userInfo ? (
                  <form onSubmit={submitHandler}>
                    <div className="my-4">
                      <strong>Rating</strong>
                      <select
                        value={rating}
                        onChange={(e) => setRating(e.target.value)}
                        className="col-12 bg-light p-3 mt-2 border-0 rounded"
                      >
                        <option value="">Select...</option>
                        <option value="1">1 - Poor</option>
                        <option value="2">2 - Fair</option>
                        <option value="3">3 - Good</option>
                        <option value="4">4 - Very Good</option>
                        <option value="5">5 - Excellent</option>
                      </select>
                    </div>
                    <div className="my-4">
                      <strong>Comment</strong>
                      <textarea
                        row="3"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        className="col-12 bg-light p-3 mt-2 border-0 rounded"
                      ></textarea>
                    </div>
                    <div className="my-3">
                      <button
                        disabled={loadingCreateReview}
                        className="col-12 bg-black border-0 p-3 rounded text-white"
                      >
                        SUBMIT
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="my-3">
                    <Message variant={"alert-warning"}>
                      Please{" "}
                      <Link to="/login">
                        " <strong>Login</strong> "
                      </Link>{" "}
                      to write a review{" "}
                    </Message>
                  </div>
                )}
              </div>
              {/* Related product */}
              <Benefits />
              {/* <RelatedProducts /> */}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SingleProduct;
