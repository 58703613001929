import React from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { Link, useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./parallax.css";
import { FiChevronLeft } from "react-icons/fi";
import { BiChevronRight } from "react-icons/bi";
import MaximizeIcon from '@mui/icons-material/Maximize';

const RecentStory = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      paritialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 30
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0,
    }
  };


  const sliderImageUrl = [
    //First image url
    {
      url: "https://www.sauceshop.co/cdn/shop/articles/20231016101100-img_2343-20-2_1080x.jpg?v=1697532782",
      name: "BUFFALO SCAMPI BITES",
      price: 19.99,
    },
    {
      url: "https://www.sauceshop.co/cdn/shop/articles/20231018143248-img_2531_1080x.png?v=1697709843",
      name: "HOT SAUCE GLAZED HASSELBACK POTATOES",
      price: 13.49,
    },
    //Second image url
    {
      url: "https://www.sauceshop.co/cdn/shop/articles/20231113153008-recipe-20-20brie-20bites-20large2-20-2_1080x.png?v=1699889417",
      name: "FRIED BRIE BITES",
      price: 17.0,
    },
    //Third image url
    {
      url: "https://www.sauceshop.co/cdn/shop/articles/20230920093758-september_1080x.jpg?v=1695286851",
      name: "CHILLI JAM CHEESE TOASTIE",
      price: 9.99,
    },

    //Fourth image url

    {
      url: "https://www.sauceshop.co/cdn/shop/articles/20230929075120-img_2171_1080x.jpg?v=1695987161",
      name: "MOTHER TOMATO LASAGNE BITES",
      price: 2.99,
    },
    {
      url: "https://www.sauceshop.co/cdn/shop/articles/X_5_1080x.png?v=1693987418",
      name: "CURRY KETCHUP BAKED CHICKEN WINGS",
      price: 2.99,
    },
    {
      url: "https://www.sauceshop.co/cdn/shop/articles/20230831102401-salmonrecipe.png?v=1693477453",
      name: "SCOTCH BONNET SALMON SANDWICH",
      price: 2.99,
    },
    {
      url: "https://www.sauceshop.co/cdn/shop/articles/20230823101727-untitled-20design-20-30.png?v=1692881461",
      name: "JERK SAUSAGE ROLLS WITH A SCOTCH BONNET CHILLI JAM DIP",
      price: 2.99,
    },
    {
      url: "https://www.sauceshop.co/cdn/shop/articles/20230818132432-bigmacwraps_1080x.jpg?v=1694772721",
      name: "BIG MAC TACOS",
      price: 2.99,
    },
    {
      url: "https://www.sauceshop.co/cdn/shop/articles/20230818143509-dsc_1121_smaller_1080x.jpg?v=1692626955",
      name: "PORK AND PRAWN CHILLI JAM BURGER",
      price: 2.99,
    },
  ];

  const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
      <li
        className={active ? "active" : "inactive"}
        onClick={() => onClick()}
      >
       <MaximizeIcon sx={{color: `${active ?'green':'#dedede'}  `}}/>
      </li>
    );
  };
  return (
    <div className="container">
      <h1
        style={{
          textTransform: "uppercase",
          marginTop: "2rem",
          fontWeight: "700",
          marginLeft: "2rem",
        }}
      >
        Recent Story
      </h1>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        customDot={<CustomDot />}
        infinite={true}
        partialVisible={false}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        arrows={false}
        // partialVisbile
        centerMode={false}
      >
        {sliderImageUrl.map((imageUrl, index) => {
          return (
            <Link to="/shop">
              <div className="slider" key={index}>
                <img
                  src={imageUrl.url}
                  alt="movie"
                  style={{
                    width: "300px",
                    height: "300px",
                    objectFit: "cover",
                    marginBottom: "1rem",
                  }}
                />
                <p style={{ textTransform: "capitalize" }}>{imageUrl.name}</p>
                <small
                  style={{
                    textDecoration: "underline",
                    textDecorationColor: "green",
                    fontWeight: "500",
                    color: "green",
                    fontStyle: "italic",
                  }}
                >
                  Read more
                </small>
              </div>
            </Link>
          );
        })}
      </Carousel>
    </div>
  );
};

export default RecentStory;
