import React from "react";
import ImgHero from "./ImgHero";
import Img2 from "./Img2";
import Img3 from "./Img3";
import NewArrivalsParalax1 from "./NewArrivalsParalax1";
import NewArrivalsParalax2 from "./NewArrivalsParalax2";
import RecentStory from "./RecentStory";

const Parallax1 = () => {
  return (
    <div className="container">
      <ImgHero />
      <NewArrivalsParalax1 />
      <Img2 />
      <NewArrivalsParalax2 />
      <Img3 />
      <RecentStory />
    </div>
  );
};

export default Parallax1;
