import React from "react";
import "./parallax.css";
import { Parallax, Background } from "react-parallax";
import img1 from "../Asset/img1.webp";
import { Typography, Box, TablePagination, Button } from "@mui/material";

const ImgHero = () => {
  return (
    <Parallax
      className="image"
      blur={0}
      bgImage={img1}
      strength={1000}
      bgImageStyle={{ minHeight: "100vh" }}
    >
      <div className="content">
        <span className="img-txt">
          THE ULTIMATE CHRISTMAS LEFTOVERS TOASTIE
        </span>
        <Button
          variant="contained"
          sx={{
            bgcolor: "#f3fbf7",
            color: "#24632b",
            borderRadius: "5px",
            fontWeight: 600,
            border: "1px solid #24632b",
            "&:hover": {
              bgcolor: "#24632b",
              color: "#fff",
            },
          }}
          href="/shop"
          className="animated tada"
        >
          Brower Offers
        </Button>
      </div>
    </Parallax>
  );
};

export default ImgHero;
