
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
// import './dropdown.css'
import { ReactComponent as BellIcon } from './icons/bell.svg';
import { ReactComponent as MessengerIcon } from './icons/messenger.svg';
import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { ReactComponent as CogIcon } from './icons/cog.svg';
import { ReactComponent as ChevronIcon } from './icons/chevron.svg';
import { ReactComponent as ArrowIcon } from './icons/arrow.svg';
import { ReactComponent as BoltIcon } from './icons/bolt.svg';
import { CSSTransition } from 'react-transition-group';



const DropDown2 = () => {
    const [activeMenu, setActiveMenu] = useState('main');
    const [menuHeight, setMenuHeight] = useState(null);
    const dropdownRef = useRef(null);

    const setfilteredProduct = useSelector(
        (state) => state.filterProductListReducer.setfilteredProduct
      );
      // console.log(setfilteredProduct)
      const allCategories = [
        ...new Set(setfilteredProduct.map((item) => item.category)),
        "all",
      ];
      // console.log({allCategories});
    
      const allBrands= [
        ...new Set(setfilteredProduct.map((item) => item.company)),
        "all",
      ]

  
    useEffect(() => {
      setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
    }, [])
  
    function calcHeight(el) {
      const height = el.offsetHeight+30;
      setMenuHeight(height);
    }
  
    function DropdownItem(props) {
      return (
        <a href="#" className="menu-item" onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}>
          <span className="icon-button">{props.leftIcon}</span>
          {props.children}
          <span className="icon-button">{props.rightIcon}</span>
        </a>
      );
    }
  return (
    <div className="dropdown"
    //  style={{ height: menuHeight }}
     ref={dropdownRef}>
    <CSSTransition
      in={activeMenu === 'main'}
      timeout={500}
      classNames="menu-primary"
      unmountOnExit
      onEnter={calcHeight}>
      <div className="menu">
        {/* <DropdownItem>My Profile</DropdownItem> */}
        <DropdownItem
          leftIcon={<CogIcon />}
          rightIcon={<ChevronIcon />}
          goToMenu="settings">
          Categories
        </DropdownItem>
        <DropdownItem
          leftIcon="🦧"
          rightIcon={<ChevronIcon />}
          goToMenu="animals">
          Brands
        </DropdownItem>

      </div>
    </CSSTransition>

    <CSSTransition
      in={activeMenu === 'settings'}
      timeout={500}
      classNames="menu-secondary"
      unmountOnExit
      onEnter={calcHeight}>
      <div className="menu">
        <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
          <h6>Categories</h6>
        </DropdownItem>
        {allCategories.map((category)=>(
            <DropdownItem >{category}</DropdownItem>
        )) }
          </div>
    </CSSTransition>

    <CSSTransition
      in={activeMenu === 'animals'}
      timeout={500}
      classNames="menu-secondary"
      unmountOnExit
      onEnter={calcHeight}>
      <div className="menu">
        <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
          <h6>Brands</h6>
          <li class="divider"></li>
        </DropdownItem>
        {allBrands.map((brand)=>(
             <DropdownItem >{brand}</DropdownItem>
        ))}
      </div>
    </CSSTransition>
  </div>
  )
}

export default DropDown2