import {
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_FILTERED_LIST_REQUEST,
  PRODUCT_FILTERED_LIST_SUCCESS,
  PRODUCT_FILTERED_LIST_FAIL,
  SET_FILTERED_PRODUCTS,
  SEND_CATEGORY,
  SEND_BRAND,
} from "../Constants/ProductConstants";

// PRODUCT LIST
export const productListReducer = (state = { products: [] }, action) => {
  // console.log({action})
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        pages: action.payload.pages,
        page: action.payload.page,
        products: action.payload.products,
      };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

const productiState = {
  setfilteredProduct: [],
  filteredProduct: [],
};

//FILTER PRODUCT REDUCER
export const filterProductListReducer = (state = productiState, action) => {
  // console.log({action})
  switch (action.type) {
    case SET_FILTERED_PRODUCTS:
      return { ...state, loadingfilter: true, setfilteredProduct: action.payload };

    case SEND_CATEGORY:
      let category = action.payload;
      let x = state.setfilteredProduct.filter((item) => item.category === category);
      if (action.payload === "all") { 
        x=[]
        
        }

      return { ...state, loadingfilter: true, filteredProduct: x };
    
      case SEND_BRAND:
        let brand = action.payload;
        let x_brand = state.setfilteredProduct.filter((item) => item.company === brand);
        if (action.payload === "all") {x_brand=[] }
        return { ...state, loadingfilter: true, filteredProduct: x_brand };

    
    default:
      return state;
  }
};

// SINGLE PRODUCT
export const productDetailsReducer = (
  state = { product: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// PRODUCT REVIEW CREATE
export const productCreateReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};
