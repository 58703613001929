import React from "react";
import './categiries.css'
const Brandrunning = () => {
  const brandsruning = [
    {
      url: "/images/logoproducts/cholimex.png",
    },
    {
      url: "/images/logoproducts/DHFood.jpg",
    },
    {
      url: "/images/logoproducts/masan.png",
    },
    {
      url: "/images/logoproducts/songhuong.png",
    },
    {
      url: "/images/logoproducts/vifon.jpg",
    },
    {
      url: "/images/logoproducts/vissan.png",
    },
  ];

//   var copy = document.querySelector(".logos-slide2").cloneNode(true);
//   document.querySelector(".logos2").appendChild(copy);
  return (
    <div class="logos2">
        
      <div class="logos-slide2">
        {brandsruning.map((img)=>(
            <img src={img.url} />
        ))}
      </div>
    </div>
  );
};

export default Brandrunning;
