import React from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { Link, useHistory } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./parallax.css";

const NewArrivalsParalax1 = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 770 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  const sliderImageUrl = [
    //First image url
    {
      url: "https://www.sauceshop.co/cdn/shop/files/SauceShopWebsiteImagesSqueezy_CurryKetchup_900x.png?v=1692257509",
      name: "SAUCE SHOP X ONLY SCRANS - CURRY KETCHUP",
      price: 3.99,
    },
    {
      url: "https://www.sauceshop.co/cdn/shop/files/SAUCESHOPJARWEBSITEIMAGES_RanchMayo_900x.png?v=1687508711",
      name: "RANCH MAYO",
      price: 3.49,
    },
    //Second image url
    {
      url: "https://www.sauceshop.co/cdn/shop/products/SAUCESHOPGLASSSAUCEWEBSITEIMAGES_BrusselSproutKetchup_fa8c21a2-c87b-408a-8e6c-e44dca181d1e_900x.png?v=1676556484",
      name: "BRUSSELS SPROUT KETCHUP",
      price: 3.49,
    },
    //Third image url
    {
      url: "https://www.sauceshop.co/cdn/shop/files/SAUCESHOPJARWEBSITEIMAGES_ScotchBonnetJam-14_ee574d7b-0011-48fe-9d54-41208a91f6e8_900x.png?v=1695821357",
      name: "SAUCE SHOP X 12:51 - SCOTCH BONNET CHILLI JAM",
      price: 3.99,
    },

    //Fourth image url

    {
      url: "https://www.sauceshop.co/cdn/shop/products/SAUCESHOPGLASSSAUCEWEBSITEIMAGES_SmokyChipotleKetchup_e2db5229-e45f-48b9-8e4f-0fab72dfbe89_900x.png?v=1691068075",
      name: "SMOKY CHIPOTLE KETCHUP",
      price: 3.49,
    },
  ];
  return (
    <div className="container">
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        arrows={false}
      >
        {sliderImageUrl.map((imageUrl, index) => {
          return (
            <Link to="/shop">
              <div className="slider" key={index}>
                <img src={imageUrl.url} alt="movie" />
                <p>{imageUrl.name}</p>
                <small>{imageUrl.price}</small>
              </div>
            </Link>
          );
        })}
      </Carousel>
    </div>
  );
};

export default NewArrivalsParalax1;
