import { Parallax } from 'react-parallax';
import img3 from '../Asset/img3.webp'
import { Typography, Box, TablePagination, Button } from "@mui/material";

const Img3 = () => (
    <Parallax className='image' blur={0} bgImage={img3} strength={1000} bgImageStyle={{minHeight:"100vh"}}>
        <div className='content'>
            <span className="img-txt">JAMES COCHRAN'S PRAWN TOAST</span>
            <Button
          variant="contained"
          sx={{
            bgcolor: "#f3fbf7",
            color: "#24632b",
            borderRadius: "5px",
            fontWeight: 600,
            border: "1px solid #24632b",
            "&:hover": {
              bgcolor: "#24632b",
              color: "#fff",
            },
          }}
          href="/shop"
          className="animated tada"
        >
          Discover
        </Button>
        </div>
    </Parallax>
);

export default Img3