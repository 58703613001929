import React from "react";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { Link, useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./parallax.css";
import { FiChevronLeft } from "react-icons/fi";
import { BiChevronRight } from "react-icons/bi";

const NewArrivalsParalax2 = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 770 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div
        className="carousel-button-group mb-4  gap-4 flex justify-end 
        items-center w-full"
      >
        <button className="block p-3 bg-slate-300" onClick={() => previous()}>
          {" "}
          <FiChevronLeft />
        </button>
        <button onClick={() => next()}>
          <span className="block p-3 bg-slate-300">
            <BiChevronRight />
          </span>
        </button>
      </div>
    );
  };

  const sliderImageUrl = [
    //First image url
    {
      url: "https://www.sauceshop.co/cdn/shop/files/GIFTSETSWEBSITEIMAGES_HotSauceChallengeSetFront_1.webp?v=1702485130",
      name: "HOT SAUCE CHALLENGE SET",
      price: 19.99,
    },
    {
      url: "https://www.sauceshop.co/cdn/shop/products/GIFTSETSWEBSITEIMAGES_CUSTOMGiftBoxExample_62376449-dc19-458c-a9a0-ee814715f2fc_900x.png?v=1676556372",
      name: "BBQ SAUCE TRIO GIFT SET",
      price: 13.49,
    },
    //Second image url
    {
      url: "https://www.sauceshop.co/cdn/shop/products/SauceShop_Collections_Simmer-Sauce_900x.png?v=1678459513",
      name: "SIMMER-SAUCE COLLECTION",
      price: 17.0,
    },
    //Third image url
    {
      url: "https://www.sauceshop.co/cdn/shop/products/merch-sauce-shop-branded-caddy-1_900x.jpeg?v=1642420632",
      name: "SAUCE SHOP BRANDED CADDY",
      price: 9.99,
    },

    //Fourth image url

    {
      url: "https://www.sauceshop.co/cdn/shop/products/merch-sauce-shop-white-label-tote-bag-1_900x.png?v=1676556471",
      name: "WHITE LABEL TOTE BAG",
      price: 2.99,
    },
  ];
  return (
    <div className="container">
      <Carousel
        responsive={responsive}
        autoPlay={true}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        partialVisible={false}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        arrows={false}
      >
        {sliderImageUrl.map((imageUrl, index) => {
          return (
            <Link to="/shop">
              <div className="slider" key={index}>
                <img src={imageUrl.url} alt="movie" />
                <p style={{ textTransform: "capitalize" }}>{imageUrl.name}</p>
                <small>{imageUrl.price}</small>
              </div>
            </Link>
          );
        })}
      </Carousel>
    </div>
  );
};

export default NewArrivalsParalax2;
