import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../Redux/Actions/userActions";
import Menu from "./Menu/Menu";
import MenuMobile from "./Menu/MenuMobile";

import { IoMdHeartEmpty } from "react-icons/io";
import { BsCart } from "react-icons/bs";
import { BiMenuAltRight } from "react-icons/bi";
import { VscChromeClose } from "react-icons/vsc";

import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Typography, Box } from "@mui/material";
import Menumulty from "./Headercomponent/Menumulty";
import Search from "./Headercomponent/Search";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import TuneOutlined from "@mui/icons-material/TuneOutlined";
import Drawer from "@mui/material/Drawer";
import MenuMobileSlide from "./Headercomponent/MenuMobileSlide";

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [showCatMenu, setShowCatMenu] = useState(false);
  const [showBrandMenu, setShowBrandMenu] = useState(false);
  const [show, setShow] = useState("translate-y-0");
  const [lastScrollY, setLastScrollY] = useState(0);
  const [categories, setCategories] = useState("");

  const controlNavbar = () => {
    if (window.scrollY > 200) {
      if (window.scrollY > lastScrollY && !mobileMenu) {
        setShow("-translate-y-[80px]");
      } else {
        setShow("shadow-sm");
      }
    } else {
      setShow("translate-y-0");
    }
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);

  // end of Menu section
  const [keyword, setKeyword] = useState();
  const dispatch = useDispatch();
  let history = useHistory();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const total = cartItems.reduce((a, i) => a + i.qty * i.price, 0).toFixed(2);

  const logoutHandler = () => {
    dispatch(logout());
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      history.push(`/search/${keyword}`);
    } else {
      history.push("/");
    }
  };

  // SEARCH SECTION

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
    zIndex: 999,
    color: "green",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "0ch",
        "&:focus": {
          width: "20ch",
          background: "#24632b",
          color: "white",
        },
      },
    },
  }));

  const annountment = "Receive 20 NZD off when you ";
  const annoutbold = "SIGN UP";
  // Drawer Menu mobile

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };
  return (
    <div >
      {/* Top Header */}
      <div className="Announcement d-flex sticky-top">
        <div className="col-md-4 d-flex align-items-center text-white display-none ">
          <p className="mx-3">Find a store</p>
        </div>
        <div className="col-md-4 col-12 justify-content-center d-flex align-items-center text-white">
          {/* PC annountment */}
          <Box display={{ xs: "none", lg: "block" }}>
            <p>
              {annountment}
              <span style={{ textDecoration: "underline" }}>{annoutbold}</span>
            </p>
          </Box>
          {/* Mobile annountment */}
          <Box display={{ xs: "flex", sm: "none" }}>
            <Box display="flex" alignItems="center">
              <Typography
                textAlign="center"
                marginLeft="5px"
                marginRight="5px"
                sx={{
                  fontFamily: "Josefin Sans, sans-serif",
                  fontSize: "14px",
                }}
              >
                {annountment}
                <span style={{ textDecoration: "underline" }}>
                  {annoutbold}
                </span>
              </Typography>
            </Box>

            {userInfo ? (
              <div
                className="drop-down"
                style={{ background: "transparent !important" }}
              >
                <button
                  type="button"
                  className="name-button dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    background: "#24632b",
                    border: "none",
                    color: "#fff",
                  }}
                >
                  {userInfo.name}
                </button>

                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/profile">
                    Profile
                  </Link>

                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={logoutHandler}
                  >
                    Logout
                  </Link>
                </div>
              </div>
            ) : (
              <div
                className="btn-group"
                style={{ background: "transparent !important" }}
              >
                <button
                  type="button"
                  className="name-button dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{
                    background: "#24632b",
                    border: "none",
                    color: "#fff",
                  }}
                >
                  <FaceOutlinedIcon />
                </button>

                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/login">
                    Login
                  </Link>

                  <Link className="dropdown-item" to="/register">
                    Register
                  </Link>
                </div>
              </div>
            )}
          </Box>
        </div>
        <div className="col-md-4 justify-content-lg-end d-flex align-items-center text-white display-none ">
          <a href="tel: +64204563939" className="text-white mx-3">
            <p>+64 20 456 3939</p>
          </a>
          <a
            href="https://www.facebook.com/shopcotu.co.nz"
            target="_blank"
            className="text-white mx-3"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
        </div>
      </div>
      {/* Header */}
      <div className="header">
        {/* MOBILE HEADER */}
        <div className="mobile-header">
          <div className="col-12">
            <div className="mobile-header1">
              <div className="col-6">
                <Link className="navbar-brand" to="/">
                  <img alt="logo" src="/images/shopcotu/shopcotu_text.png" />
                </Link>
              </div>
              <div className="col-6 d-flex align-items-center  justify-content-end">
                <div
                  className="d-flex justify-center"
                  style={{ minWidth: 60, borderLeft: "1px solid #24632b" }}
                >
                  {/* <Search /> */}
                  <Search />
                </div>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    borderLeft: "1px solid #24632b",
                    minHeight: 60,
                    minWidth: 60,
                  }}
                >
                  <Link to="/cart">
                    <ShoppingCartOutlinedIcon sx={{ color: "#24632b" }} />
                    <span className="badge" style={{ color: "red" }}>
                      {cartItems.length}
                    </span>
                  </Link>
                  {/* <span >${total}</span> */}
                </div>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    borderLeft: "1px solid #24632b",
                    minHeight: 60,
                    minWidth: 60,
                  }}
                >
                  {mobileMenu ? (
                    <VscChromeClose
                      style={{ fontSize: "20px", color: "#24632b" }}
                      // onClick={() => setMobileMenu(false)}
                      onClick={toggleDrawer("left", false)}
                    />
                  ) : (
                    <BiMenuAltRight
                      style={{ fontSize: "20px", color: "#24632b" }}
                      // onClick={() => setMobileMenu(true)}
                      onClick={toggleDrawer("left", true)}
                    />
                  )}
                </div>
              </div>
              <Drawer
                BackdropProps={{ invisible: true }}
                open={state.left}
                onClose={toggleDrawer("left", false)}
                PaperProps={{
                  sx: {
                    height: "calc(100% - 140px)",
                    top: 112,
                    width: "100%",
                  },
                }}
              >
                <MenuMobileSlide />
              </Drawer>
            </div>
          </div>
        </div>

        {/* PC HEADER */}
        <div className="pc-header d-flex align-items-center">
          <div
            style={{
              borderLeft: "1px solid #24632b",
              height: "60px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Menumulty />
          </div>
          <div
            className="col-md-3 d-flex align-items-center justify-content-center"
            style={{ borderLeft: "1px solid #24632b", height: "60px" }}
          >
            <Link className="navbar-brand" to="/">
              <img alt="logo" src="/images/shopcotu/shopcotu_text.png" />
            </Link>
          </div>
          <div
            className="col-md-5 d-flex align-items-center ml-2"
            style={{ borderLeft: "1px solid #24632b", height: "60px" }}
          >
            <Search />
          </div>
          {/* <div className="col-md-6 col-8 d-flex align-items-center">
                <form onSubmit={submitHandler} className="input-group">
                  <input
                    type="search"
                    className="form-control rounded search"
                    placeholder="Search"
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                  <button type="submit" className="search-button">
                    search
                  </button>
                </form>
              </div> */}

          <div
            className="col-md-2 d-flex align-items-center justify-content-end Login-Register p-2"
            style={{ borderLeft: "1px solid #24632b", height: "60px" }}
          >
            {userInfo ? (
              <div className="btn-group">
                <button
                  type="button"
                  className="name-button dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Hi, {userInfo.name}
                </button>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="/profile">
                    Profile
                  </Link>

                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={logoutHandler}
                  >
                    Logout
                  </Link>
                </div>
              </div>
            ) : (
              <>
                {/* <Link to="/register">Register</Link> */}
                <Link to="/login">
                  <FaceOutlinedIcon sx={{ color: "#24632b" }} />
                </Link>
              </>
            )}
            <Link to="/cart">
              <ShoppingCartOutlinedIcon sx={{ color: "#24632b" }} />
              <span className="badge">{cartItems.length}</span>
            </Link>
            <span>${total}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
