export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const PRODUCT_CREATE_REVIEW_REQUEST = "PRODUCT_CREATE_REVIEW_REQUEST";
export const PRODUCT_CREATE_REVIEW_SUCCESS = "PRODUCT_CREATE_REVIEW_SUCCESS";
export const PRODUCT_CREATE_REVIEW_FAIL = "PRODUCT_CREATE_REVIEW_FAIL";
export const PRODUCT_CREATE_REVIEW_RESET = "PRODUCT_CREATE_REVIEW_RESET";

// FILTER SECTION

export const FILTER_BY_SEARCH = "FILTER_BY_SEARCH";
export const SORT_PRODUCTS = "SORT_PRODUCTS";
export const FILTER_BY_CATEGORY = "FILTER_BY_CATEGORY";
export const FILTER_BY_BRAND = "FILTER_BY_BRAND";
export const FILTER_BY_PRICE = "FILTER_BY_PRICE";

export const PRODUCT_FILTERED_LIST_REQUEST = "PRODUCT_FILTERED_LIST_REQUEST";
export const PRODUCT_FILTERED_LIST_SUCCESS = "PRODUCT_FILTERED_LIST_SUCCESS";
export const PRODUCT_FILTERED_LIST_FAIL = "PRODUCT_FILTERED_LIST_FAIL";

export const SET_FILTERED_PRODUCTS = "SET_FILTERED_PRODUCTS";
export const SEND_CATEGORY = "SEND_CATEGORY";
export const SEND_BRAND = "SEND_BRAND";

