import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box, TablePagination, IconButton } from "@mui/material";
import {
  listProduct,
  listfilteredProduct,
  setFilterProducts,
} from "../../../Redux/Actions/ProductActions";
import { URL } from "../../../Url";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import NewArrivals from "./NewArrivals";
import GiftandCollection from "./GiftandCollection";
import Brandrunning from "./Brandrunning";

const Categories = () => {
  const dispatch = useDispatch();

  const setfilteredProduct = useSelector(
    (state) => state.filterProductListReducer.setfilteredProduct
  );
  // console.log(setfilteredProduct)
  const allCategories = [
    ...new Set(setfilteredProduct.map((item) => item.category)),
    "all",
  ];
  // console.log({allCategories});

  const allBrands = [
    ...new Set(setfilteredProduct.map((item) => item.company)),
    "all",
  ];

  // GET FULL PRODUCT SEND TO STATE
  useEffect(() => {
    getApiData();
  }, []);

  const getApiData = async () => {
    const response = await fetch(`${URL}/api/products/allproducts`);
    const data = await response.json();
    // console.log({ data });
    dispatch(setFilterProducts(data));
  };

  // tab New arrival
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const AntTabs = styled(Tabs)({
    // borderBottom: '1px solid #24632b',
    "& .MuiTabs-indicator": {
      backgroundColor: "#24632b",
    },
  });

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "uppercase",
      minWidth: 0,
      [theme.breakpoints.up("sm")]: {
        minWidth: 0,
      },
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(0),
      color: "rgba(0, 0, 0, 0.85)",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#24632b",
        opacity: 1,
        fontWeight: 600,
      },
      "&.Mui-selected": {
        color: "#fff",
        background: "#24632b",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&.Mui-focusVisible": {
        backgroundColor: "#d1eaff",
      },
    })
  );
  return (
    <div className="containers">
      {/* Tab New Arrivals */}
      <Box marginTop={5}>
        {/* PC display */}
        <Box display={{ xs: "none", sm: "block" }}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <AntTab
              label="New Arrivals"
              {...a11yProps(0)}
              sx={{
                borderRight: 1,
                borderColor: "divider",
                fontFamily: "Josefin Sans, sans-serif",
              }}
              className="animated tada"

            />
            <AntTab
              label="Gifts & Collection"
              {...a11yProps(1)}
              sx={{
                borderRight: 1,
                borderColor: "divider",
                fontFamily: "Josefin Sans, sans-serif",
              }}
            />
          </AntTabs>
        </Box>
        {/* Mobile display */}
        <Box display={{ xs: "block", sm: "none" }}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="fullWidth"
          >
            <AntTab
              label="New Arrivals"
              {...a11yProps(0)}
              sx={{
                borderRight: 1,
                borderColor: "divider",
                fontFamily: "Josefin Sans, sans-serif",
              }}
              className="animated tada"
            />
            <AntTab
              label="Gifts & Collection"
              {...a11yProps(1)}
              sx={{
                borderRight: 1,
                borderColor: "divider",
                fontFamily: "Josefin Sans, sans-serif",
              }}
            />
          </AntTabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <NewArrivals />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} sx={{ background: "#f3fbf7" }}>
          <GiftandCollection />
        </CustomTabPanel>
      </Box>

      {/* Categories */}
      <Box display="flex" flexDirection="Column" marginBottom={3} marginTop={5}>
        <h1
          style={{
            textTransform: "uppercase",
            marginTop: "2rem",
            fontWeight: "700",
            marginLeft: "2rem",
          }}
        >
          Categories
        </h1>
        <Box
          margin="2rem auto"
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(2, 1fr)",
            md: "repeat(auto-fill, 200px)",
          }}
          justifyContent="space-around"
          rowGap="10px"
          columnGap="10px"
          minWidth={{ md: "1280px" }}
          // sx={{border: '1px solid red'}}
          className="container"
        >
          {allCategories.map((category, index) => (
            <>
              {index < 6 ? (
                <IconButton
                  href="/shop"
                  sx={{
                    border: "1px solid #dedede",
                    borderRadius: "5px",
                    backgroundColor: "#f3fbf7",
                    "&:hover": {
                      backgroundColor: "green",
                      color: "#fff",
                      
                    },

                    ponter: "cursor",
                  }}
                  display="flex"
                  justifyContent="center"
                  
                >
                  <h6
                    style={{
                      textTransform: "uppercase",
                      margin: "0.5rem 2rem",
                    }}
                    className="animated tada"
                  >
                    {category}
                  </h6>
                </IconButton>
              ) : null}
            </>
          ))}
        </Box>
      </Box>

      {/* Brands running */}
      <Box>
        <h1
          style={{
            textTransform: "uppercase",
            marginTop: "2rem",
            fontWeight: "700",
            marginLeft: "2rem",
          }}
        >
          ALSO AVAILABLE FROM
        </h1>
        <Brandrunning />
      </Box>
    </div>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, borderBottom: "red", background: "#f3fbf7" }}>
          <Typography color="#24632b">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default Categories;
