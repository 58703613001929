import Category from "./Category/Category";
import Price from "./Price/Price";
import Colors from "./Colors/Colors";
import "./Sidebar.css";
import { Box,  IconButton, Typography, Button } from "@mui/material";
import Brands from "./Brands/Brands";


const Sidebar = ({ handleChange }) => {
  return (
    <>
      <Box ml="0px" mt="0px">
        <Category handleChange={handleChange} />
        <Brands />
        {/* <Price handleChange={handleChange} />
        <Colors handleChange={handleChange} /> */}
      </Box>
    </>
  );
};

export default Sidebar;
