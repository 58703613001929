import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import Pagination from "./pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  listProduct,
  listfilteredProduct,
  setFilterProducts,
} from "../../Redux/Actions/ProductActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import { getDiscountedPricePercentage } from "../utils/helper";

// filter
import Navigation from "./Navigation/Nav";
import Products from "./Products/Products";
import products1 from "./db/data";
import Recommended from "./Recommended/Recommended";
import Sidebar from "./Sidebar/Sidebar";
import Card from "./components/Card";
import "./filter.css";
import { URL } from "../../Url";
import Herophotoland from "./photoland/Herophotoland";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, Button, IconButton, Typography } from "@mui/material";

// Filter Mobile Version
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Introsection from "./IntroSection/Introsection";
import CloseIcon from '@mui/icons-material/Close';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

const ShopSection = (props) => {
  const { keyword, pagenumber } = props;
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;
  // const loading=true
  // console.log({products})
  const filteredProduct2 = useSelector(
    (state) => state.filterProductListReducer.filteredProduct
  );
  console.log({ filteredProduct2 });
  const [filter, setFilter] = useState(false);
  const { loadingfilter } = filteredProduct2;
  // console.log({loadingfilter})

  useEffect(() => {
    dispatch(listProduct(keyword, pagenumber));
  }, [dispatch, keyword, pagenumber]);

  // GET FULL PRODUCT SEND TO STATE
  useEffect(() => {
    getApiData();
  }, []);

  const getApiData = async () => {
    const response = await fetch(`${URL}/api/products/allproducts`);
    const data = await response.json();
    // console.log({ data });
    dispatch(setFilterProducts(data));
  };

  ////
  const original_price = 300;
  // console.log({ products });

  const filteredProduct = useSelector(
    (state) => state.filterProductListReducer
  );
  // console.log({filteredProduct})

  //FILTER SECTION
  const [selectedCategory, setSelectedCategory] = useState(null);
  // console.log({products1})

  // ----------- Input Filter -----------
  const [query, setQuery] = useState("");

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const filteredItems = products.filter(
    (product) => product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
  );

  // // ----------- Radio Filtering -----------
  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  // // ------------ Button Filtering -----------
  const handleClick = (event) => {
    setSelectedCategory(event.target.value);
  };

  function filteredData(products, selected, query) {
    let filteredProducts = products;

    // Filtering Input Items
    if (query) {
      filteredProducts = filteredItems;
    }

    // Applying selected filter
    if (selected) {
      filteredProducts = filteredProducts.filter(
        ({ category, color, company, price, title }) =>
          category === selected ||
          color === selected ||
          company === selected ||
          price === selected ||
          title === selected
      );
    }

    return filteredProducts.map(
      ({ image, title, star, reviews, prevPrice, price }) => (
        <Card
          key={Math.random()}
          img={image}
          title={title}
          star={star}
          reviews={reviews}
          prevPrice={prevPrice}
          newPrice={price}
        />
      )
    );
  }

  const result = filteredData(products, selectedCategory, query);
  // console.log({result})

  const [showFilter, setShowFilter] = useState(false);

  //use Drawer in Mobile version
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      // sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"      
      // onKeyDown={toggleDrawer(anchor, false)}
      ml="20px"
    > 
      {/* <Box display="flex" alignItems="center" borderBottom="1px solid gray">
        <h5>FILTER</h5>
        <img alt="logo" src="/images/shopcotu.png" width={40} style={{margin:"1rem auto"}}/>
        <CloseIcon sx={{marginRight: '1rem'}} onClick={toggleDrawer(anchor, false)}/>
      </Box> */}
      
      <Sidebar />
    </Box>
  );

  return (
        <div >
          <div >
            <div className="col-lg-12 col-md-12">
              <div className="shopcontainer">
                {loading ? (
                  <div >
                    <Loading />
                  </div>
                ) : error ? (
                  <Message variant="alert-danger">{error}</Message>
                ) : (
                  <>
                    {filteredProduct2.length === 0 ? (
                      <>
                        <Introsection />
                        <Divider />
                        <div className="d-flex justify-content-between my-4 align-items-center">
                          <Box display="flex" >
                          <p>
                            {showFilter ? (
                              <div onClick={()=>{setShowFilter(!showFilter)}} className="d-flex align-items-center cursor-pointer my-1">
                                <TuneOutlinedIcon className="mx-3"/>
                                <Typography textTransform="uppercase" fontFamily="Josefin Sans, sans-serif" fontWeight={400}>Hide Filter</Typography>
                                <Box display={{xs: "block",sm:'none'}}>
                                {['left'].map((anchor) => (
                                  <React.Fragment key={anchor}>
                                    <Button onClick={toggleDrawer('left', true)}>Hide Fitlter</Button>
                                    <Drawer
                                      anchor={anchor}
                                      open={state[anchor]}
                                      onClose={toggleDrawer(anchor, false)}
                                      PaperProps={{
                                        sx: {
                                          height: 'calc(100% - 120px)',
                                          top: 120,
                                          width: '75%'
                                        },
                                      }}
                                    >
                                      {list(anchor)}
                                    </Drawer>
                                  </React.Fragment>
                                ))}
                              </Box>
                                
                              </div>
                            ) : (
                              <div onClick={()=>setShowFilter(!showFilter)} className="d-flex align-items-center my-1">
                              <TuneOutlinedIcon className="mx-3"/>
                              <Typography textTransform="uppercase" fontFamily="Josefin Sans, sans-serif" fontWeight={400}>Filter</Typography>

                            </div>
                              
                            )}
                          </p>
                                 </Box>
                          <Recommended />
                        </div>
                        
                        <Box display="flex"  columnGap="0px" >
                          {/* PC Filter */}
                          {showFilter ? <Box flex="1 1 25%" ml="00px" mt="0px" display={{xs: "none", md:'block'}}><Sidebar /></Box> : null}
                          {/* Mobile Filter */}
                          <Box flex={`${showFilter ? "1 1 75%" : "1"}} `} mb="0px">    
                          <Box         
                              margin="0 auto"
                              display="grid"
                              gridTemplateColumns={{xs: "repeat(2, 1fr)", md:"repeat(auto-fill, 310px)" }}
                              justifyContent="space-around"
                              rowGap="10px"
                              columnGap="0%"
                          >
                        {products.map((product) => (
                          <div
                            className="shop"
                            key={product._id}
                          >
                            <div className="border-product">
                              <Link to={`/products/${product._id}`}>
                                <div className="shopBack">
                                  <img src={product.image} alt={product.name} />
                                  <div className="shopBack_rating">
                                    <Rating
                                      value={product.rating}
                                      // text={`${product.numReviews} reviews`}
                                    />
                                    <small className="text-black-50">
                                      ({getDiscountedPricePercentage(
                                        product.prevPrice,
                                        product.price
                                      )}
                                      % off)
                                    </small>
                                  </div>
                                </div>
                              </Link>

                              <div className="shoptext">
                                <p >
                                  <Link to={`/products/${product._id}`}>
                                    {product.name}
                                  </Link>
                                </p>

                                <div className="d-flex">
                                  <div className="d-flex m-auto justify-content-start col-12 mt-2 align-items-end mb-2">
        
                                      <h6 className="fw-bold text-success mx-2">
                                         {(product.price).toFixed(2)}
                                      </h6>
                                      {product.prevPrice && (
                                        <div>
                                          <h6 className="text-black-50 text-decoration-line-through">
                                            {Math.round(product.prevPrice).toFixed(2)}
                                          </h6>
                                        </div>
                                      )}  
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        </Box>
                        </Box>
                        </Box>
                        {/* <div style={{position: "relative", height:'120vh', width: '100%'}}>
                                            <div style={{position:'absolute', top:'0px', left:'0px'}}>
                                              <Sidebar handleChange={handleChange} />
                                            </div>
                                            <div style={{position:'absolute', top:'0px', left:'200px', minWidth:'85%'}}>
                                                  <Navigation query={query} handleInputChange={handleInputChange} />
                                                  <Recommended handleClick={handleClick} />
                                            </div>

                                            <div style={{position:'absolute', top:'180px', left:'200px',  maxwidth:'85%'}}>
                                                  <Products result={result} />
                                            </div>
                   
                    </div>  */}
                      </>
                    ) : (
                      <>
                        <Introsection />
                        <Divider />
                        <div className="d-flex justify-content-between my-4 align-items-center ">
                          <Box display="flex" borderBottom="3px solid #ff9a00">
                          <p>
                            {showFilter ? (
                              <div onClick={()=>{setShowFilter(!showFilter)}} className="d-flex align-items-center cursor-pointer my-2">
                                <VisibilityOffOutlinedIcon className="mx-1"/>
                                <Typography textTransform="uppercase" fontFamily="Josefin Sans, sans-serif" fontWeight={700}>Hide Filter</Typography>
                                <Box display={{xs: "block",sm:'none'}}>
                                {['left'].map((anchor) => (
                                  <React.Fragment key={anchor}>
                                    <Button onClick={toggleDrawer('left', true)} className="text-black">Hide Fitlter</Button>
                                    <Drawer
                                      anchor={anchor}
                                      open={state[anchor]}
                                      onClose={toggleDrawer(anchor, false)}
                                    >
                                      {list(anchor)}
                                    </Drawer>
                              </React.Fragment>
                            ))}
                          </Box>
                                
                              </div>
                            ) : (
                              <div onClick={()=>setShowFilter(!showFilter)} className="d-flex align-items-center my-3">
                              <VisibilityOutlinedIcon className="mx-2"/>
                              <small className="text-uppercase">Show Filter</small>
                            </div>
                              
                            )}
                          </p>
                                 </Box>
                          <Recommended />
                        </div>
                        
                        <Box display="flex"  columnGap="0px" >
                          {/* PC Filter */}
                          {showFilter ? <Box flex="1 1 25%" ml="00px" mt="0px" display={{xs: "none", md:'block'}}><Sidebar /></Box> : null}
                          {/* Mobile Filter */}
                          <Box flex={`${showFilter ? "1 1 75%" : "1"}} `} mb="0px">    
                          <Box         
                              margin="0 auto"
                              display="grid"
                              gridTemplateColumns={{xs: "repeat(auto-fill, 200px)", md:"repeat(auto-fill, 300px)" }}
                              justifyContent="space-around"
                              rowGap="0px"
                              columnGap="0%"
                          >
                        {filteredProduct2.map((product) => (
                          <div
                            className="shop"
                            key={product._id}
                          >
                            <div className="border-product">
                              <Link to={`/products/${product._id}`}>
                                <div className="shopBack">
                                  <img src={product.image} alt={product.name} />
                                  <div className="shopBack_rating">
                                    <Rating
                                      value={product.rating}
                                      // text={`${product.numReviews} reviews`}
                                    />
                                    <small className="text-black-50">
                                      ({getDiscountedPricePercentage(
                                        product.prevPrice,
                                        product.price
                                      )}
                                      % off)
                                    </small>
                                  </div>
                                </div>
                              </Link>

                              <div className="shoptext">
                                <p >
                                  <Link to={`/products/${product._id}`}>
                                    {product.name}
                                  </Link>
                                </p>

                                <div className="d-flex">
                                  <div className="d-flex m-auto justify-content-center col-12 mt-3 align-items-end">
                                    {product.prevPrice && (
                                        <div>
                                          <h6 className="text-black-50 text-decoration-line-through">
                                            {Math.round(product.prevPrice).toFixed(2)}
                                          </h6>
                                        </div>
                                      )}
                                      <h6 className="fw-bold text-success mx-2">
                                         {(product.price).toFixed(2)}
                                      </h6>  
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        </Box>
                        </Box>
                        </Box>
                      </>
                    )}
                  </>
                )}

                {/* Pagination */}
                <Pagination
                  pages={pages}
                  page={page}
                  keyword={keyword ? keyword : ""}
                />
              </div>
            </div>
          </div>
        </div>
  );
};

export default ShopSection;
