import React from "react";

const ContactInfo = () => {
  return (
    <div className="contactInfo container">
      <div className="row">
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-phone-alt"></i>
            </div>
            <h5>Call Us 24x7</h5>
            <p>020 456 3939</p>
          </div>
        </div>
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
            <i class="far fa-map-marker-alt"></i>
            </div>
            <h5>Headquarter</h5>
            <p>Christchurch, New Zealand</p>
          </div>
        </div>
        <div className="col-12 col-md-4 contact-Box">
          <div className="box-info">
            <div className="info-image">
              <i className="fas fa-fax"></i>
            </div>
            <h5>Facebook</h5>
            <a href="https://www.facebook.com/shopcotu.co.nz" target="_blank" style={{textDecoration:'none'}}><p>Shopcotu FanPage</p></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
