import React from "react";
import "./parallax.css";
import { Parallax, Background } from "react-parallax";
import img2 from '../Asset/img2.webp'
import { Typography, Box, TablePagination, Button } from "@mui/material";

const Img2 = () => {
  return (
    <Parallax className='image' blur={0} bgImage={img2} strength={1000} bgImageStyle={{minHeight:"100vh"}}>
        <div className='content'>
            <span className="img-txt">BUFFALO BLUE CHEESE POTATO POPS</span>
            <Button
          variant="contained"
          sx={{
            bgcolor: "#f3fbf7",
            color: "#24632b",
            borderRadius: "5px",
            fontWeight: 600,
            border: "1px solid #24632b",
            "&:hover": {
              bgcolor: "#24632b",
              color: "#fff",
            },
          }}
          href="/shop"
          className="animated tada"
        >
          Discover
        </Button>
        </div>
    </Parallax>
  );
};

export default Img2;
