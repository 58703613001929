// import "./Category.css";
import Input from "../../components/Input";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { sendBrand, sendCategory } from "../../../../Redux/Actions/ProductActions";
import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Checkbox from '@mui/material/Checkbox';


function Brands() {
  const setfilteredProduct = useSelector(
    (state) => state.filterProductListReducer.setfilteredProduct
  );
  // console.log(setfilteredProduct)
  const dispatch = useDispatch();
  const allBrands= [
    ...new Set(setfilteredProduct.map((item) => item.company)),
    "all",
  ]
  // console.log({allCategories})


  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(-90deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  return (
    <>
     <Card variant="none" sx={{borderBottom:'1px solid #f3fbf7'}}>
      <CardActions disableSpacing>
        <Typography  sx={{ fontFamily: "Josefin Sans, sans-serif", fontWeight: 600, textTransform:'uppercase' }}>Company</Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="canned"
            name="radio-buttons-group"
          >
            {allBrands.map((brand) => (
              <FormControlLabel
                value={brand}
                control={<Radio size="small" color="warning"/>}
                label={<Typography             sx={{
                  fontFamily: "Josefin Sans, sans-serif",
                  textTransform: "uppercase",
                  fontSize:"1rem",
                  color:'warning'
                }}>{brand}</Typography>}
                onClick={() => dispatch(sendBrand(brand))}

              />
            ))}
          </RadioGroup>
        </CardContent>
      </Collapse>
    </Card>

    </>
  );
}

export default Brands;
