import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import MainSlider from "./MainSlider";

const Introsection = () => {
  return (
    <Box
      display="flex"
      flex="1 1"
      flexWrap="wrap"
      flexDirection={{ xs: "column", sm: "row" }}
    >
      {/* sidebar */}
      {/* slider */}
      <Box flex="1 1 50%" padding="2rem">
        <MainSlider />
      </Box>
      <Box flex="1 1 50%" sx={{ background: {xs:"transparent",sm: "#24632b"} }}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ maxHeight: "300px", padding: "0px" }}
        >
          {/* text */}
          <div
            className="d-flex flex-column"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <Typography
              variant="h5"
              fontFamily="Chakra Petch, sans-serif"
              color="#ff9a00"
              margin={{xs:"0",sm:"1.5rem 0"}}
              marginBottom={{xs:"1rem"}}
              fontWeight="bold"
              padding={{xs: '0rem 1rem'}}
              textAlign="center"
            >
              MAKE ROOM FOR MORE FLAVOUR
            </Typography>
            <Typography
              display={{ xs: "none", sm: "block" }}
              fontFamily="Josefin Sans, sans-serif"
              color="white"
              padding="0 1rem"
              textAlign="center"
              marginBottom="1rem"
            >
              Choose from our full range of award winning sauces and seasonings.
              From our 2 star Great Taste Award winning Tomato Ketchup to some
              wild limited editions. Our sauces are versatile - use them as a
              glaze, stir them into a recipe, pour them on your plate, drink
              them for all we care. They taste great and they look nice on your
              table. Win win!
            </Typography>
            <Button variant="contained" sx={{background:'#ff9a00', marginBottom:'2rem'}}>
              Shop now
            </Button>
          </div>
          <img src="" alt="" style={{ maxWidth: "200px", margin: "auto 0" }} />
        </div>
      </Box>
      {/* promo 1 */}
    </Box>
  );
};

export default Introsection;
