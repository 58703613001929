import React, { useEffect } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removefromcart } from "../Redux/Actions/cartActions";
import Hero from "../components/IntroComponents/Hero/Hero";
import Footer from "../components/Footer";
import Categories from "../components/IntroComponents/Categories/Categories";
import Parallax1 from "../components/IntroComponents/Parallax/Parallax";

const Introduction = ({ match, location, history }) => {
  // window.scrollTo(0, 0);
  const dispatch = useDispatch();
  const productId = match.params.id;
  const qty = location.search ? Number(location.search.split("=")[1]) : 1;

  
  return (
    < >
      <Header />
      <Hero />
      <Categories />
      <Parallax1 />
      <Footer />
    </>
  );
};

export default Introduction;
