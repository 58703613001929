import React from "react";
import "./slider.css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { BiArrowBack } from "react-icons/bi";

const images = [
  {
    id: "102",
    author: "Ben Moore",
    width: 4320,
    height: 3240,
    url: "https://unsplash.com/photos/pJILiyPdrXI",
    download_url: "/images/Slide/1.jpg",
  },
  {
    id: "103",
    author: "Ilham Rahmansyah",
    width: 2592,
    height: 1936,
    url: "https://unsplash.com/photos/DwTZwZYi9Ww",
    download_url: "/images/Slide/2.jpg",
  },
  {
    id: "104",
    author: "Dyaa Eldin",
    width: 3840,
    height: 2160,
    url: "https://unsplash.com/photos/2fl-ocJ5MOA",
    download_url: "/images/Slide/3.jpg",
  },
  {
    id: "106",
    author: "Arvee Marie",
    width: 2592,
    height: 1728,
    url: "https://unsplash.com/photos/YnfGtpt2gf4",
    download_url: "/images/Slide/4.jpg",
  },
  {
    id: "107",
    author: "Lukas Schweizer",
    width: 5000,
    height: 3333,
    url: "https://unsplash.com/photos/9VWOr22LhVI",
    download_url: "/images/Slide/5.jpg",
  },
  {
    id: "108",
    author: "Florian Klauer",
    width: 2000,
    height: 1333,
    url: "https://unsplash.com/photos/t1mqA3V3-7g",
    download_url: "/images/Slide/6.jpg",
  },
  {
    id: "110",
    author: "Florian Klauer",
    width: 2000,
    height: 1333,
    url: "https://unsplash.com/photos/t1mqA3V3-7g",
    download_url: "/images/Slide/7.png",
  },
  {
    id: "111",
    author: "Florian Klauer",
    width: 2000,
    height: 1333,
    url: "https://unsplash.com/photos/t1mqA3V3-7g",
    download_url: "/images/Slide/8.png",
  },
  {
    id: "112",
    author: "Florian Klauer",
    width: 2000,
    height: 1333,
    url: "https://unsplash.com/photos/t1mqA3V3-7g",
    download_url: "/images/Slide/9.jpg",
  },
];

const MainSlider = () => {
  return (
    <div>
      <Carousel
        autoPlay={false}
        infiniteLoop={true}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        className="crsl"
        renderArrowPrev={(clickHandler, hasPrev) => (
          <div
            onClick={clickHandler}
            className="arrow-back "
          >
            <BiArrowBack  className="text-white"/>
          </div>
        )}
        renderArrowNext={(clickHandler, hasNext) => (
          <div
            onClick={clickHandler}
            className="arrow-next"
          >
            <BiArrowBack  className="text-white"/>
          </div>
        )}
      >
        {images.map((image) => (
          <img src={image.download_url} alt={image.author} style={{width: '100%', aspectRatio: '14/9', objectFit:'cover', maxHeight:'200px'}}/>
        ))}
      </Carousel>
    </div>
  );
};

export default MainSlider;
